import React, { useState, useRef } from 'react';
import './bannerBenefits.css'

function BannerBenefits ({children}) {
  const [lightPosition, setLightPosition] = useState({ x: 0, y: 0 });
  const [lightVisible, setLightVisible] = useState(false);
  const lightRef = useRef(null);


  const handleMouseMove = (event) => {
    if (lightRef.current) {
      const { clientX, clientY } = event;
      const light = lightRef.current;
      const x = clientX - light.offsetWidth ;
      const y = clientY - light.offsetHeight ;
      setLightPosition({ x, y });

    }
  };

  const handleMouseEnter = () => {
    setLightVisible(true);
  };

  const handleMouseLeave = () => {
    setLightVisible(false);
  };

  

  return (
    <div className="bannerBenefits bg_dark">
        <div className='container'>
            <div
                className="effect-container"
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
                <div
                className="light-effect"
                ref={lightRef}
                style={{
                    transform: `translate(${lightPosition.x}px, ${lightPosition.y}px)`,
                    opacity: lightVisible ? 1 : 0,
                }}
                ></div>
            </div>
        </div>
    </div>
  );
}

export default BannerBenefits ;
