import React from 'react';
import './videoStaff.css'
import { useTranslation } from 'react-i18next';


function VideoStaff() {

  const { t } = useTranslation('translation', 
  { keyPrefix: 'staff' });

  return (
    <div className="video-staff video_area bg_color ">
        <div className="container">
          <div className='container'>
            <h2 className='w_color text-center f--size-40 f_600'>{t('video_part.title')}</h2>
            <br></br>
            <h2 className='w_color text-center'>{t('video_part.sub_title')}</h2>
              <figure className="imgClients pb-4">
                  <img src={require('img/clients/logo-square-trade.png')} alt="Logo Client Square Trade" loading="lazy" />
              </figure>
          </div>
          <div className="video_content">          
            <iframe className='video_info' 
                    frameBorder="0" 
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  
                    title="Sueña para transformar el futuro." 
                    width="770" 
                    height="360" 
                    src="https://www.youtube.com/embed/KWcKeJDUriY?si=X0JdDPHM4e2sPkW6" >
            </iframe>  
          </div>  
        </div>
    </div>
  )
}

export default VideoStaff;